<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

@import "~primevue/resources/themes/bootstrap4-light-blue/theme.css";
@import "~primevue/resources/primevue.min.css";
@import "~primeicons/primeicons.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import "assets/wellon.css";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/

/* CUSTOM CSS */
.form-control:focus + div.input-group-append > .btn {
  border-color: #69b3ff;
}

.form-control.p-multiselect,
.form-control.p-dropdown {
  padding: 2px 0 0 0 !important;
  display: inline-flex !important;
}

.p-inputtext,
.p-placeholder,
.p-multiselect-item,
.p-dropdown-item,
.p-dropdown-empty-message,
.p-multiselect-empty-message,
.p-multiselect-label {
  font-family: Poppins, Helvetica, "sans-serif" !important;
}

.form-group.has-danger div.input-group-append > .btn {
  border-left: none;
  border-color: #f64e60 !important;
}

.form-group.has-success div.input-group-append > .btn {
  border-left: none;
  border-color: #1bc5bd !important;
}

.form-group > label:first-child {
  font-weight: 600;
}

.table-hover tbody tr:hover .table-success {
  background-color: #abeae7;
}

.table-hover tbody tr:hover .table-primary {
  background-color: #aed5ff;
}

.pagination .page-link {
  padding-top: 0.747rem;
  padding-bottom: 0.747rem;
}

code.whatsapp {
  background-color: transparent;
  padding: 0;
  font-weight: 400;
  border-radius: 0;
  font-size: 100%;
  color: #000000;
  word-wrap: break-word;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, Courier, monospace;
}

.card.card-custom.card-expand
  .card-header
  .card-toolbar
  .card-button-collapse
  i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.card.card-custom.card-collapsed
  .card-header
  .card-toolbar
  .card-button-collapse
  i,
.card.card-custom.card-collapse
  .card-header
  .card-toolbar
  .card-button-collapse
  i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* Estilo base das mensagens formatadas enviadas pelo backend */
.response-message {
  padding: 15px;
  border-radius: 8px; /* Bordas arredondadas */
  margin-bottom: 20px; /* Espaçamento */
  font-family: "Roboto", Arial, sans-serif; /* Fonte moderna */
  font-size: 16px; /* Texto um pouco maior */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
}

/* Texto adicional */
.response-message p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.response-message strong {
  font-weight: 600;
}

/* Links para mais informações */
.response-message a {
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s;
}

.response-message a:hover {
  color: #0056b3; /* Azul para hover */
}

/* Mensagem de erro */
.response-message.error {
  border: 1px solid #f5c2c7;
  background-color: #f8d7da;
  color: #842029;
  animation: fadeIn 0.3s ease-in-out; /* Animação de entrada */
}

/* Mensagem de sucesso */
.response-message.success {
  border: 1px solid #badbcc;
  background-color: #d1e7dd;
  color: #0f5132;
  animation: fadeIn 0.3s ease-in-out;
}

/* Mensagem de aviso */
.response-message.warning {
  border: 1px solid #ffecb5;
  background-color: #fff3cd;
  color: #664d03;
  animation: fadeIn 0.3s ease-in-out;
}

/* Links para mais informações */
.response-message a {
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s;
}

.response-message a:hover {
  color: #0056b3; /* Azul para hover */
}

/* Animação de fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { Howl } from "howler";
import { mapGetters } from "vuex";
import ApiService from "./core/services/api.service";

export default {
  name: "Wellon",
  data() {
    return {
      intervalNotificacaoAgenda: null
    };
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.iniciarRequisicaoNotificacaoAgenda();
  },
  beforeDestroy() {
    clearInterval(this.intervalNotificacaoAgenda);
  },
  computed: {
    ...mapGetters(["recursoAtivoConta", "isAuthenticated"])
  },
  watch: {
    "$route.path"(atual, anterior) {
      if (atual == "/login") {
        clearInterval(this.intervalNotificacaoAgenda);
      }
      if (anterior == "/login" && atual == "/painel/dashboard") {
        this.iniciarRequisicaoNotificacaoAgenda();
      }
    }
  },
  methods: {
    iniciarRequisicaoNotificacaoAgenda() {
      if (this.recursoAtivoConta("AGENDAMENTO") && this.isAuthenticated) {
        clearInterval(this.intervalNotificacaoAgenda);

        this.intervalNotificacaoAgenda = setInterval(() => {
          if (!this.recursoAtivoConta("AGENDAMENTO") || !this.isAuthenticated) {
            clearInterval(this.intervalNotificacaoAgenda);
            return;
          }
          ApiService.get("/agenda/notificacao", null, false).then(
            (response) => {
              let quant_notificacao =
                response.data.agendamento_erro_sincronia +
                response.data.agendamento_novos;
              if (quant_notificacao > 0) {
                const notification = {
                  title: "Agendamento - Wellon Digital",
                  options: {
                    body: response.data.mensagem
                  },
                  events: {
                    onclick: function () {
                      window.open(
                        "https://painel.wellon.digital/painel/agenda",
                        "_blank"
                      );
                    }
                  }
                };
                this.$notification.show(
                  notification.title,
                  notification.options,
                  notification.events
                );
              }
            }
          );
        }, 900000); // 15 min
      }
    }
  }
};
</script>
